<template>
  <VueContentHolder id="divDigitalLiteracyWrapper" :padding="[20, 20, 10]">
    <div class="doughnut">
      <div class="doughnut-headline">
        <VueText color="grey-40" sizeLevel="6">{{ contentTypeName }} Tamamlama Oranı</VueText>
      </div>
      <div class="doughnut-wrapper">
        <BrandDoughnutChart
          :key="renderKey"
          :chartData="doughnutDataChart"
          :chartOptions="doughnutOptionsChart"
          class="doughnut-chart"
        />
        <VueText class="mock-text" color="grey-40" sizeLevel="6"
          >%{{ completedPercentage }}</VueText
        >
      </div>
      <!-- İş birimi talebi ile kaldırıldı.-->
      <!-- <div class="rank-wrapper">
        <VueText color="grey-40" sizeLevel="6" weightLevel="3"
          >Sıralama: <span class="rank-value">{{ rank }}</span></VueText
        >
      </div> -->
    </div>
    <VueSpacer level="30"></VueSpacer>
    <VueHeadline level="2" weightLevel="4">Eğitim Videoları</VueHeadline>
    <VueSpacer level="20"></VueSpacer>
    <div v-for="item in orderedMediaList" :key="item.videoId">
      <BrandMediaListItem
        :text="item.title"
        :percentage="item.percentWatched"
        :mediaSource="item"
        @handleRoute="handleVideoRouting(item)"
      ></BrandMediaListItem>
    </div>
  </VueContentHolder>
</template>

<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import BrandDoughnutChart from '@/components/brand/BrandChart/BrandDoughnutChart.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesLearn from '@/router/routes/secure/RoutesLearn';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps.js';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import { Learn } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'DigitalLiteracy',
  components: {
    VueContentHolder,
    VueSpacer,
    BrandDoughnutChart,
    VueText,
    VueHeadline,
    BrandMediaListItem,
  },
  mixins: [StorageProps, vimeoUtils, gtmUtils],
  data() {
    return {
      contentTypeId: 0,
      contentTypeName: '',
      renderKey: 0,
      completedPercentage: '',
      mediaItems: [],
      // rank: 1,
      doughnutDataChart: {
        labels: ['Tamamlanmış', 'Tamamlanmamış'],
        datasets: [
          {
            label: 'Tamamlama Oranı',
            backgroundColor: ['#50d2c2', '#b4c2d3'],
            data: [],
          },
        ],
      },
      doughnutOptionsChart: {
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: true,
        rotation: Math.PI * 300,
        circumference: 270,

        tooltips: {
          mode: 'label',
          backgroundColor: '#ffffff',
          titleFontColor: '#23303d',
          bodyFontColor: '#23303d',
          titleFontStyle: 'normal',
          bodyFontStyle: 'bold',
        },
      },
    };
  },
  computed: {
    orderedMediaList() {
      return this.mediaItems.slice().sort((a, b) => {
        if (a.videoId > b.videoId) {
          return 1;
        } else if (a.videoId < b.videoId) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },

  created() {
    if (this.$route.params && this.$route.params.contentTypeId) {
      Learn.getLearnContentTypes()
        .then(res => {
          if (res.data && res.data.Data) {
            let contentItem = res.data.Data.contentTypes.find(
              x => x.contentTypeId == this.$route.params.contentTypeId,
            );
            if (contentItem) {
              this.contentTypeId = contentItem.contentTypeId;
              this.contentTypeName = contentItem.contentName;

              this.setAppBarTitle(this.contentTypeName);
              Learn.getContentByLevelId(this.contentTypeId)
                .then(res => {
                  const response = res.data.Data;
                  if (response) {
                    this.completedPercentage = response.percent.toFixed(2);
                    this.pushDataLayerEvent('learn', {
                      event: 'completed-percentage',
                      category: this.contentTypeName,
                      percent: response.percent.toFixed(2),
                    });
                    this.doughnutDataChart.datasets[0].data[0] = this.completedPercentage;
                    this.doughnutDataChart.datasets[0].data[1] = 100 - this.completedPercentage;
                    // this.rank = response.rank;
                    this.mediaItems = response.videos
                      .filter(item => item.link && item.link.length > 0)
                      .map(item => ({
                        videoId: item.id,
                        title: item.title,
                        levelId: this.contentTypeId,
                        viewStatus: item.status,
                        percentWatched: item.percent,
                        playbackPosition: item.playbackPosition,
                        isStarting: false,
                        isViewed: item.viewCount > 0 ? true : false,
                        embedVideo: item.link.replace(/\r?\n|\r/g, '').trim(),
                      }));
                  }
                })
                .then(() => {
                  this.renderKey += 1;
                  this.mediaItems = this.getVideoThumbs(this.mediaItems);
                });
            } else {
              this.goToLearn();
            }
          } else {
            this.goToLearn();
          }
        })
        .catch(() => this.goToLearn());
    } else {
      this.goToLearn();
    }
  },
  methods: {
    goToLearn() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}`);
    },
    handleVideoRouting(item) {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
      }).set(item);
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.VideoDetail.path}`,
      );
    },
  },
};
</script>

<style scoped lang="scss">
.doughnut {
  margin-top: palette-space-level('20');
  padding: palette-space-level('20');
  border: 1px solid palette-color-level('grey', '20');
  border-radius: palette-radius-level('2');
  &-chart {
    max-width: 200px;
    max-height: 200px;
    margin: auto;
    justify-content: center;
    display: flex;
  }
  &-wrapper {
    position: relative;

    .mock-text {
      position: absolute;
      top: calc(50% + 10px);
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: palette-font-size-level('13');
      font-weight: 300;
      color: #1d1d26;
    }
  }
  &-headline {
    display: flex;
    align-items: center;
    margin-bottom: palette-space-level('20');

    svg {
      margin-right: palette-space-level('10');
    }
  }
}

// .rank {
//   &-wrapper {
//     border-top: 1px solid palette-color-level('grey', '20');
//     padding: palette-space-level('10') palette-space-level('10');
//   }
//   &-value {
//     color: palette-color-level('grey', '40');
//     font-weight: palette-font-weight-level('3');
//   }
// }
</style>
